<template>
  <div class="auth-wrapper auth-v2" :style="backgroundStyle">
    <b-spinner v-if="isLoading" type="grow" class="mt-5 mx-auto d-block" />
    <b-row v-else class="auth-inner m-0" align-v="center">
      <!-- Login-->
      <b-col
        cols="12"
        md="8"
        offset-md="2"
        class="d-flex align-items-center my-0 p-0 col"
      >
        <b-card class="px-3 px-md-2 mx-auto my-md-3 my-0 auth-bg w-100">
          <b-row>
            <b-col cols="12" class="pl-3 pr-1">
              <safe-img :src="communityLogo" class="login-logo mb-3 mt-2 round" />
              <h1 class="font-weight-bold w-100 text-center mb-2" title-tag="h1">
                {{ translate(community.name) }}
              </h1>
              <b-card-text class="mb-2 text-center h4">
                {{ $t('backoffice.legal.center') }}
                <hr class="d-block mx-auto w-25 mx-5 px-5"></hr>
              </b-card-text>
              <p class="font-weight-bold mb-0 text-center">
                {{ $t('backoffice.legal.center-headline') }}
              </p>
              <p class="font-weight-bold text-center mb-3">
                {{ $t('backoffice.legal.center-more') }}
              </p>
              <b-row>
                <b-col
                  v-for="menu, index in legalMenu"
                  :key="index"
                  cols="12"
                  lg="3"
                  class="mb-2"
                >
                  <b-card class="cards pointer" @click="changeCurrent(menu)" :class="menu===selectedText ? 'bg-light menu-border':''">
                    <span
                      class="b-avatar mb-2 badge-primary bg-light-primary rounded-circle d-block mx-auto"
                      style="width: 45px; height: 45px;"
                    >
                      <feather-icon size="23" :icon="getIcon(menu)" class="mt-75" />
                      <font-awesome-icon v-if="menu === 'cookies_policy'" :icon="['fa', 'cookie-bite']" style="width: 1.5rem; height: 1.5rem; margin-top: 12px" />
                    </span>
                    <h5 class="mt-50 pointer text-center" :class="menu===selectedText? 'font-weight-bolder text-primary':''">
                      {{ selectedTitle(menu) }}
                    </h5>
                  </b-card>
                </b-col>
              </b-row>
              <div v-if="legalText[selectedText]&&Object.values(legalText[selectedText]).length > 0 && selectedText !== 'cookies_policy'">
                <div v-html="legalText[selectedText][currentLocale] || Object.values(legalText[selectedText])[0]" />
              </div>
              <div v-else-if="selectedText === 'cookies_policy'">
                <span v-html="getCookiesVersion" />
              </div>
              <div v-else-if="selectedText==='privacy_policy'" style="text-align:justify; padding:20px;">
                <privacy-text-versions />
              </div>
              <div v-else-if="selectedText==='terms_conditions'" style="text-align:justify; padding:20px;">
                <terms-text-versions />
              </div>
            </b-col>
          </b-row>

          <b-card-text class="text-center  mt-3">
            <b-link :href="landingURL" target="_blank">
              <b-img :src="brand" alt="Powered by Nectios" class="brand" />
            </b-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import BgHeader from '@/assets/images/placeholders/light/banner-nectios.png';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { spanish, catalan, english } from './CookiesTextsVersions'
import TermsTextVersions from './TermsTextVersions'
import PrivacyTextVersions from './PrivacityTextVersion'

export default {
  components: {
    SafeImg,
    ValidationProvider,
    ValidationObserver,
    TermsTextVersions,
    PrivacyTextVersions,
  },
  mixins: [togglePasswordVisibility, ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      community: { name: 'Nectios' },
      legalText: {},
      legalMenu: [],
      selectedText: '',
      text: null,

      from: null,
      path: null,
    };
  },
  computed: {
    getCookiesVersion() {
      if (this.currentLocale === 'es') {
        return spanish;
      }
      if (this.currentLocale === 'ca') {
        return catalan;
      }
      return english;
    },
    currentLocale() {
      return this.$route?.query?.locale || this.$store.state.locale.currentLocale;
    },
    collective() {
      return this.$store.getters?.currentCollective;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    type() {
      return this.$route.params.type;
    },
    email() {
      return `mailto:newsletter${this.community.mainCommunitySlug}.tech`;
    },
    signUpURL() {
      let url = process.env.VUE_APP_SIGN_UP_URL.replace('{subdomain}', this.community.subdomain || 'app').replace(
        '{slug}',
        this.communitySlug,
      );
      url = `${url}?code=${this.community.language}`;
      return url;
    },
    showRegistrationLink() {
      return this.community.accessibility < 2;
    },
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }

      return null;
    },
    communityLogo() {
      return getImageResource(
        this.community?.customization?.theme?.login?.logo || this.community?.header?.logo_url || this.community?.logoURL || BgHeader,
      );
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
    localeInUrl() {
      return this.$route.query.locale;
    },
  },
  watch: {
    communitySlug() {
      this.updateCommunity();
    },
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
    },
  },
  async created() {
    this.updateCommunity();
    if (this.communitySlug === 'my') {
      this.legalText = {
        terms_conditions: null,
        privacy_policy: null,
        gdpr: null,
        cookies_policy: null,
      };
      this.legalMenu = Object.keys(this.legalText);
    }

    this.selectedText = this.type;
    await this.fetchpolicy();
    if (this.localeInUrl) {
      this.setLocale(this.localeInUrl);
    } else {
      this.setLocale(this.community.language);
    }
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async fetchpolicy() {
      this.isLoading = true;
      await this.$store.$service[
        Service.BackendClient
      ].get('communityCustomizations/ext', { params: { communitySlug: this.communitySlug } })
        .then((response) => {
          this.legalText = response.data?.community_customizations;
          this.legalMenu = Object.keys(this.legalText).filter((menu) => menu === 'terms_conditions' || menu === 'privacy_policy' || menu === 'gdpr' || menu === 'cookies_policy');
          this.isLoading = false;
        })
        .catch((e) => {
          this.legalText = e.response.data.community_customizations;
          this.legalMenu = Object.keys(this.legalText);
          this.isLoading = false;
        });
    },
    selectedTitle(word) {
      return this.$t(`legal.${word}`);
    },
    getIcon(legal) {
      const menu = {
        terms_conditions: 'FileTextIcon',
        privacy_policy: 'ShieldIcon',
        gdpr: 'LockIcon',
      };
      return menu[legal];
    },
    async updateCommunity() {
      this.isLoading = true;

      const response = await this.$store.$service[Service.BackendClient].get('login', {
        params: { communitySlug: this.communitySlug },
      });
      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations.theme) {
        const customization = this.community.community_customizations.theme;
        if (customization.primaryColor) {
          document.documentElement.style.setProperty(
            '--primary-color',
            customization.primaryColor,
          );
          let hsl = customization.primaryColor;
          hsl = hsl.substring(4, hsl.length - 1);
          hsl = hsl.split(',');
          document.documentElement.style.setProperty(
            '--primary-color-h',
            hsl[0],
          );
          document.documentElement.style.setProperty(
            '--primary-color-s',
            hsl[1],
          );
          document.documentElement.style.setProperty(
            '--primary-color-l',
            hsl[2],
          );
        }
        if (customization.secondaryColor) {
          document.documentElement.style.setProperty(
            '--secondary-color',
            customization.secondaryColor,
          );
          let hslSecondary = customization.secondaryColor;
          hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary.split(',');
          document.documentElement.style.setProperty(
            '--secondary-color-h',
            hslSecondary[0],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-s',
            hslSecondary[1],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-l',
            hslSecondary[2],
          );
        }
      }
      /* Finish colors */
      /*if (this.community.showChatbot === 1) {
        const chatBot = document.createElement('script');
        chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
        document.body.appendChild(chatBot);
      }*/
      if (this.community.showChatbot === 2) {
        const chatBot = document.createElement('div');
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }

      this.isLoading = false;
    },
    setLocale(locale) {
      this.$store.dispatch('setLocale', locale);
    },
    translateTranslationTable,
    changeCurrent(menu) {
      this.selectedText = menu;
      const url = this.$route.path.split("/").length >= 4 ? `${this.$route.path.substring(0, this.$route.path.lastIndexOf("/"))}/${menu}` : `${this.$route.path}/${menu}`
      return this.$router.push({ path: url, query: { locale: this.currentLocale }, params: { communityId: this.communitySlug, type: menu } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
@import '~@core/scss/base/bootstrap-include'; 
.login-logo {
  width: 120px;
  margin: auto;
  display: block;
}
.brand {
  height: 32px;
}
.community-text {
  padding-left: 6px;
  margin-top: 4px;
  vertical-align: bottom;
  color: black;
}
@media (max-width: 767px) {
  .card {
    height: 100%;
    border-radius: 0;
  }
  .cards {
    border-radius: 1.2rem;
  }
  .col {
    min-height: 100vh;
  }
}
.menu-border {
    border: 2px solid $primary;
  }
</style>
